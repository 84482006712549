<template>
  <div
    class="side-content-wrap"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
      ref="myData"
      class="sidebar-left rtl-ps-none ps scroll"
    >
      <div>
        <ul class="navigation-left">
          <li
            @mouseenter="toggleSubMenu"
            :class="{ active: selectedParentMenu == 'dashboards' }"
            class="nav-item"
            data-item="dashboards"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Bar-Chart"></i>
              <span class="nav-text">{{ $t("nav.sidebar.dashboard") }}</span>
            </a>

            <div class="triangle"></div>
          </li>

          <li
            class="nav-item"
            @mouseenter="toggleSubMenu"
            :class="{ active: selectedParentMenu == 'datatables' }"
            data-item="datatables"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-File-Horizontal-Text"></i>
              <span class="nav-text">{{ $t("nav.sidebar.data") }}</span>
            </a>
            <div class="triangle"></div>
          </li>
       
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'organization' }"
            data-item="organization"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Business-ManWoman"></i>
              <span class="nav-text">{{ $t("nav.sidebar.organization") }}</span>
            </a>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>

    <vue-perfect-scrollbar
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="sidebar-left-secondary ps rtl-ps-none"
    >
      <div ref="sidebarChild">

        <ul
          class="childNav d-none"
          data-parent="others"
          :class="{ 'd-block': selectedParentMenu == 'dashboards' }"
        >

         <li class="nav-item">
            <router-link tag="a" class to="/app/dashboard/workspaces">
              <i class="nav-icon i-Suitcase"></i>
              <span class="item-name">{{ $t("nav.sidebar.workspaces") }}</span>
            </router-link>
          </li>
          
         <li class="nav-item">
            <router-link tag="a" class to="/app/dashboard/sensors">
              <i class="nav-icon i-Pulse"></i>
              <span class="item-name">{{ $t("nav.sidebar.sensors") }}</span>
            </router-link>
          </li>
        </ul>

        <ul
          class="childNav d-none"
          data-parent="forms"
          :class="{ 'd-block': selectedParentMenu == 'datatables' }"
        >
          <li class="nav-item">
            <router-link tag="a" class to="/app/data/documents">
              <i class="nav-icon i-Files"></i>
              <span class="item-name">{{ $t("nav.sidebar.documents") }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link tag="a" class to="/app/data/datasets">
              <i class="nav-icon i-Big-Data"></i>
              <span class="item-name">{{ $t("nav.sidebar.datasets") }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link tag="a" class to="/app/data/rooms">
              <i class="nav-icon i-Building"></i>
              <span class="item-name">{{ $t("nav.sidebar.rooms") }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link tag="a" class to="/app/data/weather">
              <i class="nav-icon i-Clouds-Weather"></i>
              <span class="item-name">{{ $t("nav.sidebar.weather") }}</span>
            </router-link>
          </li>
        </ul>
              
        <ul
          class="childNav d-none"
          data-parent="sessions"
          :class="{ 'd-block': selectedParentMenu == 'organization' }"
        >
          <li class="nav-item">
            <router-link tag="a" class to="/app/organization/users">
              <i class="nav-icon i-Add-User"></i>
              <span class="item-name">{{ $t("nav.sidebar.users") }}</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link tag="a" class to="/app/organization/companies">
              <i class="nav-icon i-Building"></i>
              <span class="item-name">{{ $t("nav.sidebar.companies") }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
    <div
      @mouseenter="removeOverlay()"
      class="sidebar-overlay"
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
    ></div>
  </div>
  <!--=============== Left side End ================-->
</template>

<script>
//import Topnav from "./TopNav";
import { isMobile } from "mobile-device-detect";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    //Topnav
  },

  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: "",
      isMobile
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("mouseover", this.returnSelectedParentMenu);
    this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener("mouseover", this.returnSelectedParentMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"])
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",
      "changeSecondarySidebarPropertiesViaMenuItem",
      "changeSecondarySidebarPropertiesViaOverlay",
      "changeSidebarProperties"
    ]),

    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter(x => x !== "")[1];

      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }
    },
    toggleSubMenu(e) {
      //let childrens = this.$refs.sidebarChild.children;
      let parent = e.target.dataset.item;

      this.selectedParentMenu = parent;

      this.changeSecondarySidebarPropertiesViaMenuItem(true);
    },

    removeOverlay() {
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },

    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open");

      event.currentTarget.classList.toggle("open");

      dropdownMenus.forEach(dropdown => {
        dropdown.classList.remove("open");
      });
    }
  }
};
</script>

<style lang="" scoped>
</style>
