<template>
  <div class="main-header">
    <div class="logo">
      <router-link to="/"><img src="@/assets/images/logo-inverse.png" alt /></router-link>
    </div>

    <div @click="sideBarToggle" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="d-flex align-items-center d-none" style="display: none !important; ">
      <!-- Mega menu -->
      <div
        :class="{ show: isMegaMenuOpen }"
        class="dropdown mega-menu d-none d-md-block d-none"
        v-on-clickaway="closeMegaMenu" 
      >
        <a
          href="#"
          class="btn text-muted dropdown-toggle mr-3"
          id="dropdownMegaMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="toggleMegaMenu"
          >Menu</a
        >
        <div
          class="dropdown-menu text-left"
          :class="{ show: isMegaMenuOpen }"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="row m-0">
            <div class="col-md-4 p-4 text-left bg-img">
              <h2 class="title">
                ghub
                <br />Platform
              </h2>
              <p>
                Haal meer resultaat uit jouw energiebesparing
              </p>
              <p class=" mb-30">
                Our mission is to improve performance of buildings and cities by stimulating research and the collection of data.
              </p>
              <a target="_blank" href="https://gbdf.it" class="btn btn-lg btn-rounded btn-outline-warning">
                Lees verder
              </a>
            </div>
            <div class="col-md-4 p-4 text-left">
              <p
                class="text-primary text--cap border-bottom-primary d-inline-block"
              >
                Quick Panel
              </p>
              <div class="menu-icon-grid w-auto p-0">
                <a href="#"> <i class="i-Shop-4"></i> Projects </a>
                <a href="#"> <i class="i-Library"></i> Sensors </a>
                <a href="#"> <i class="i-Drop"></i> Plugins </a>
                <a href="#">
                  <i class="i-File-Clipboard-File--Text"></i> Datasets
                </a>
                <a href="#"> <i class="i-Checked-User"></i> Models </a>
                <a href="#"> <i class="i-Ambulance"></i> Support </a>
              </div>
            </div>
            <div class="col-md-4 p-4 text-left">
              <p
                class="text-primary text--cap border-bottom-primary d-inline-block"
              >
                Recent Projects
              </p>
              <ul class="links">
                <li>
                  <a href="accordion.html">Project Fijnhout Theater</a>
                </li>
                <li>
                  <a href="alerts.html">Radboud</a>
                </li>
                <li>
                  <a href="buttons.html">Meander Ziekenhuis</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- / Mega menu -->
      <div class="search-bar" @click="toggleSearch">
        <input type="text" placeholder="Search" />
        <i class="search-icon text-muted i-Magnifi-Glass1"></i>
      </div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
      <!-- Full screen toggle -->
      <i
        class="i-Full-Screen header-icon d-none d-sm-inline-block"
        @click="handleFullScreen"
      ></i>
      <!-- <i class="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen></i> -->
      <!-- Grid menu Dropdown -->


      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              :src="$auth.user.picture"
              id="userDropdown"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </template>

          <div class="dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> {{$auth.user.nickname}}
            </div>
            <router-link tag="a" class="dropdown-item d-none" to="/app/organization/profile">Account settings</router-link>
            <router-link tag="a" class="dropdown-item d-none" to="/app/organization/users">Organization</router-link>
            <a class="dropdown-item" href="#" @click.prevent="logoutUser">Sign out</a>
          </div>
        </b-dropdown>
      </div>
    </div>
    <search-component
      :isSearchOpen.sync="isSearchOpen"
      @closeSearch="toggleSearch"
    ></search-component>
  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from "@/utils";
//import Sidebar from "./Sidebar";
import searchComponent from "../common/search";
import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  components: {
    //Sidebar,
    searchComponent
  },

  data() {
    return {
      isDisplay: true,

      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false
    };
  },
  mounted() {
    // document.addEventListener("click", this.closeMegaMenu);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"])
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",

      "changeSidebarProperties",
      "changeThemeMode",
      "signOut"
    ]),

    // megaMenuToggle() {
    //   this.isMegaMenuOpen = false;

    //   console.log("work");
    // },

    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      this.$auth.logout();
      this.signOut();

      this.$router.push("/");
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
      // console.log(this.isMouseOnMegaMenu);
      // if (!this.isMouseOnMegaMenu) {
      //   this.isMegaMenuOpen = !this.isMegaMenuOpen;
      // }
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle() {
      if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    }
  }
};
</script>

<style scoped>

.main-header {
  background-color: #a9d18e;
}

</style>
